import React from 'react';
import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Modal } from '../../../../../components-shared/Modal';
import { Text, TextTypography } from 'wix-ui-tpa/cssVars';
import { classes } from './ShareModal.st.css';
import { useLocation } from '../../../../../contexts/Location/LocationContext';
import { FCWithChildren } from '@wix/challenges-web-library';
import { SocialShare } from '../../../../../components-shared/SocialShare';
import { useChallengeData } from '../../../../../contexts/storage-contexts/Challenge';

export interface IShareModal {
  isOpened: boolean;
  step: ParticipantStep;
  onClose(): void;
}

export const ShareModal: FCWithChildren<IShareModal> = (props) => {
  const { t } = useTranslation();
  const {
    challengeData: { challenge = {} },
  } = useChallengeData();
  const { location: { url = '' } = {} } = useLocation();

  const shareTitle = t(
    'live.challenges-page.social-share.share-title_new-syntax',
    {
      stepName: props.step?.source.settings.general.description.title.trim(),
      challengeName: challenge?.settings.description.title.trim(),
    },
  );

  const shareUrl = `${url}${
    !url.includes('?') ? '?' : '&'
  }shareTitle=${encodeURIComponent(shareTitle)}`;

  return (
    <Modal
      className={classes.socialShare}
      opened={props.isOpened}
      onClose={props.onClose}
    >
      <Text typography={TextTypography.smallTitle} tagName="h3">
        {t('live.challenges-page.social-share.title')}
      </Text>
      <SocialShare
        shareTitle={shareTitle}
        shareUrl={shareUrl}
        originalUrl={url}
      />
    </Modal>
  );
};
