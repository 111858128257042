import React from 'react';
import { FCWithChildren } from '@wix/challenges-web-library';
import { Avatar, AvatarSize, Text, TextTypography } from 'wix-ui-tpa/cssVars';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { ButtonNames } from '../../../../../../../../contexts/main/biInterfaces';
import { useSettings } from '@wix/tpa-settings/react';
import { TextAlignment } from '../../../../../../Settings/challengeSettings/challengeSettings.types';
import settingsParams from '../../../../../../settingsParams';
import { SettingsKeysAbbreviation } from '../../../../../../Settings/constants/settingsKeysAbbreviation';
import { ImageShape } from '../../../../../../../ChallengesList/Settings/challengeSettings/challengeSettings.types';
import { useLocation } from '../../../../../../../../contexts/Location/LocationContext';
import { visitorPageDataHooks } from '../../../VisitorPage';
import { st, classes } from './VisitorPageInstructors.st.css';
import { useGeneralData } from '../../../../../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { useChallengeData } from '../../../../../../../../contexts/storage-contexts/Challenge';

interface IVisitorPageInstructorsProps {
  className?: string;
  alignment: TextAlignment;
  layout?: 'box' | 'column';
}

const LinkTag: FCWithChildren<{
  isProfileInstalled: boolean;
  onClick(): void;
  url: string;
}> = (props) => {
  return props.isProfileInstalled ? (
    <a
      className={classes.instructorLink}
      href={props.url}
      onClick={props.onClick}
    >
      {props.children}
    </a>
  ) : (
    <>{props.children}</>
  );
};

function getProfileUrl(
  memberSlug: string,
  baseUrl: string,
  profileUrl: string,
) {
  if (profileUrl) {
    return profileUrl.replace('{userName}', memberSlug);
  }

  return `${baseUrl}/profile/${memberSlug}/profile`;
}

export const VisitorPageInstructors: FCWithChildren<
  IVisitorPageInstructorsProps
> = ({ className = null, alignment, layout = 'box' }) => {
  const {
    challengeData: { instructorsData },
  } = useChallengeData();
  const { isProfileInstalled, profileUrl } = useGeneralData();
  const { baseUrl } = useLocation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const imageShape: ImageShape = settings.get(
    settingsParams[
      SettingsKeysAbbreviation.visitorPageAboutInstructorImageShape
    ],
  );
  const bi = useBi();

  const sendOnClickBI = React.useCallback(() => {
    void bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.GoToInstructorProfile,
      }),
    );
  }, []);

  return (
    <ul
      data-hook={visitorPageDataHooks.author()}
      className={st(
        classes.root,
        {
          mobile: isMobile,
          alignment,
          layout,
          imageShape,
        },
        className,
      )}
    >
      {(instructorsData?.instructors || []).map((instr) => {
        const instructorProfileURL = getProfileUrl(
          instr.slug,
          baseUrl,
          profileUrl,
        );

        return (
          <li key={instr.id} className={classes.instructor}>
            <LinkTag
              url={instructorProfileURL}
              onClick={sendOnClickBI}
              isProfileInstalled={isProfileInstalled}
            >
              <Avatar
                className={classes.instructorAvatar}
                size={AvatarSize.xLarge}
                name={instr.name}
                src={instr.photo?.url || null}
              />
              <div className={classes.instructorContent}>
                <Text
                  className={classes.instructorName}
                  tagName="h4"
                  typography={TextTypography.runningText}
                >
                  {instr.name}
                </Text>
              </div>
            </LinkTag>
          </li>
        );
      })}
    </ul>
  );
};
