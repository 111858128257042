import React from 'react';

import { Badge as BadgeIcon } from '@wix/wix-ui-icons-common/on-stage';
import CertificateIcon from '../../assets/icons/certificate.svg';

import { st, classes } from './RewardInfo.st.css';

import { Reward } from '@wix/ambassador-challenges-v1-challenge/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  getBadgesTrigger,
  getCertificateTrigger,
} from '../../selectors/rewards/rewardsSelectors';
import { Box } from '../Box';
import { useChallengeData } from '../../contexts/storage-contexts/Challenge';
import { useUser } from '../../contexts/User/UserContext';
import { ViewCertificate } from '../ViewCertificate';
import { ProgramRewardTrigger } from '../../types/programs';
import { isSelfPaced } from '../../selectors/isSelfPaced';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IRewardInfoProps extends React.HTMLAttributes<HTMLElement> {
  rewards: Reward[];
  className?: string;
}

const BADGES_TRANS_MAP = {
  [ProgramRewardTrigger.ALL_STEPS_COMPLETED]: 'rewards.completed-all-steps',
  [ProgramRewardTrigger.STEP_COMPLETED]: 'rewards.completed-first-step',
  [ProgramRewardTrigger.JOINED_TO_CHALLENGE]: 'rewards.joined-challenge',
  ALL_STEPS_COMPLETED_SPC: 'rewards.completed-all-steps-spc',
};

const CERTIFICATE_TRANS_MAP = {
  [ProgramRewardTrigger.ALL_STEPS_COMPLETED]:
    'certificates.overview.reward-info.certificate',
};

export const RewardInfo: FCWithChildren<IRewardInfoProps> = (props) => {
  const { t } = useTranslation();
  const { participant } = useUser();

  const { challengeData } = useChallengeData();
  if (!props.rewards.length) {
    return null;
  }
  const badgesTrigger = getBadgesTrigger(props.rewards);
  const certificateTrigger = getCertificateTrigger(props.rewards);

  const isSPC = isSelfPaced(challengeData.challenge);

  const rewardText = isSPC
    ? BADGES_TRANS_MAP.ALL_STEPS_COMPLETED_SPC
    : BADGES_TRANS_MAP[badgesTrigger];

  return (
    <div className={st(classes.root, {}, props.className)} style={props.style}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {certificateTrigger ? (
        participant?.certificate ? (
          <Box alignItems="flex-start" gap={3}>
            <span className={classes.icon}>
              <CertificateIcon />
            </span>
            <Box>
              <span className={classes.text}>
                {t('certificates.reward-info.trigger.complete-all-steps')}
              </span>
            </Box>
            <Box>
              <ViewCertificate certificateId={participant.id} />
            </Box>
          </Box>
        ) : (
          <Box alignItems="flex-start" gap={3}>
            <span className={classes.icon}>
              <CertificateIcon />
            </span>
            <span className={classes.text}>
              {t(CERTIFICATE_TRANS_MAP[certificateTrigger])}
            </span>
          </Box>
        )
      ) : null}
      {badgesTrigger && (
        <Box alignItems="flex-start" gap={3} marginTop={2}>
          <span className={classes.icon}>
            <BadgeIcon />
          </span>
          <span className={classes.text}>{t(rewardText)}</span>
        </Box>
      )}
    </div>
  );
};
